<template>
  <main>
        <div class="mentions">
          <img src="/top-form-mobile.png">
          Notre jeu n'a pas encore commencé,<br>revenez le 1er février pour participer.
        </div>
  </main>
</template>
<script>
export default {
  name: 'contact',
}
</script>